<template>
    <div class="section-hotnews">
        <div class="container">
            <h2 class="section-hotnews__title"><img :src="webp(require(`../../../public/img/news/news-${$i18n.locale}.png`))" alt="News"></h2>
            <Breadcrumb />
            <div class="section-hotnews__content">
                <div class="section-hotnews__row">
                    <div class="section-hotnews__post" :class="{top: index === 0}" v-for="(post, index) in posts" :key="index">
                        <div class="image"><a :href="`/news/`+post.slug"><img :class="'lazyload' + ' ' +post.slug" :data-src="webp(getImage(post, index))" :alt="post.title.rendered"></a></div>
                        <div class="content">
                            <div class="date"><img width="22" :src="webp(require(`../../../public/img/news/table-grid.svg`))" alt=""> {{ post.date | formatDate('DD/MM/YYYY') }}</div>
                            <h3 class="title"><a :href="`/news/`+post.slug" v-html="post.title.rendered"></a></h3>
                            <p class="text" v-html="post.excerpt.rendered"></p>
                        </div>
                    </div><!--/ .section-hotnews__post -->
                </div><!--/ .section-hotnews__row -->
            </div><!--/ .section-hotnews__content -->
        </div><!--/ container -->
    </div>
</template>

<script>
    import {Post} from "../../mixins/post"
    import Breadcrumb from './Breadcrumb'

    export default {
        name: "HotNews",
        components: {
            Breadcrumb
        },
        mixins: [Post],
        props: {
            num: Number,
            locale: String
        },
        data() {
            return {
                page: 'hot_news',
                current_page: 1,
                numberposts: 5,
            }
        },
        methods: {
            getImage: function(post, index) {
                var img = '';
                if(post.better_featured_image) {
                    if(index === 0 && post.better_featured_image.media_details.sizes.medium_large) {
                        img = post.better_featured_image.media_details.sizes.medium_large.source_url;
                    } else if(index === 0 && !post.better_featured_image.media_details.sizes.medium_large) {
                        img = post.better_featured_image.source_url;
                    } else {
                        img = post.better_featured_image.media_details.sizes.medium.source_url;
                    }
                } else {
                    img = 'img/no-image-available.png';
                }
                return img;
            }
        }
    }
</script>

<style lang="scss">
    @import '../../assets/scss/mixin/_mixin';

    $sm: 768;
    $md: 992;
    $lg: 1200;

    .section-hotnews {
        @include media(set-range, $lg - 1, $sm) {
            padding-top: 50px;
        }
        @include media(set-min-w, $lg) {
            padding-top: 97px;
        }

        &__title {
            position: relative;
            display: none;
            padding-bottom: 7px;
            padding-left: 8px;
            @include media(set-min-w, $sm) {
                display: block;
                margin-bottom: 2rem;
            }
            @include media(set-min-w, $lg) {
                margin-bottom: 2rem;
            }

            &::after {
                content: '';
                display: none;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 15px;
                background-color: #ffaf04;
                border-radius: 50px;
                z-index: -1;

                @include media(set-min-w, $sm) {
                    display: block;
                }
            }
        }

        &__row {
            display: flex;
            flex-wrap: wrap;
            padding: 0 10px;
        }

        &__post {
            flex: 0 0 100%;
            max-width: 100%;
            display: flex;
            position: relative;
            margin-bottom: 1.2rem;
            @include media(set-min-w, $sm) {
                flex: 0 0 50%;
                max-width: 50%;
                margin-bottom: 3.2rem;
            }
            @include media(set-min-w, $lg) {
                margin-bottom: 5.3rem;
            }

            &.top {
                display: block;
                flex-grow: 1;
                max-width: none;
                margin: 0 -2rem 2.2rem;
                @include media(set-min-w, $sm) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    display: flex;
                    align-items: center;
                    margin: 0 0 3.5rem;
                }
                @include media(set-min-w, $lg) {
                    margin-bottom: 5.5rem;
                }

                .item {
                    display: block;
                    @include media(set-min-w, $sm) {
                        display: flex;
                        align-items: center;
                    }
                }
            }

            .item {
                display: flex;
            }
        }

        &__content {
            padding-bottom: 1rem;
            font-size: 1.6rem;

            a {
                color: inherit;

                &:hover {
                    text-decoration: underline;
                }
            }

            .section-hotnews__post.top {
                .image {
                    flex: 0 0 100%;
                    max-width: 100%;
                    @include media(set-min-w, $sm) {
                        flex: 0 0 50%;
                        max-width: 50%;
                    }
                }

                .content {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    padding: 1rem 2rem .5rem;
                    background-color: rgba(#000, .6);
                    @include media(set-min-w, $sm) {
                        position: relative;
                        flex: 0 0 50%;
                        max-width: 620px;
                        padding-bottom: 1rem;
                        padding-left: 3rem;
                        background-color: transparent;
                    }
                    @include media(set-min-w, $md) {
                        padding-left: 4rem;
                    }
                }

                .date {
                    margin-bottom: .3rem;
                    color: #fff;
                    @include media(set-min-w, $sm) {
                        margin-bottom: 1rem;
                        color: #3f3f3f;
                    }

                    img {
                        filter: invert(1);
                        @include media(set-min-w, $sm) {
                            filter: invert(.3);
                        }
                    }
                }

                .title {
                    max-height: 6.5rem;
                    line-height: normal;
                    color: #fff;
                    font-size: 2rem;
                    @include media(set-min-w, $sm) {
                        margin-bottom: .7rem;
                        color: #252525;
                    }
                    @include media(set-min-w, $md) {
                        max-height: 10rem;
                        line-height: 4.7rem;
                        font-size: 3.8rem;
                    }
                }

                .text {
                    max-height: 7.5rem;
                    @include media(set-min-w, $sm) {
                        max-width: 475px;
                    }
                    @include media(set-min-w, $md) {
                        max-height: 10rem;
                    }
                }
            }

            .image {
                flex: 0 0 10rem;
                max-width: 10rem;
                @include media(set-range, $md - 1, $sm) {
                    flex: 0 0 15rem;
                    max-width: 15rem;
                }
                @include media(set-min-w, $md) {
                    flex: 0 0 20rem;
                    max-width: 20rem;
                }

                img {
                    width: 100%;
                }
            }

            .content {
                flex-grow: 1;
                padding-top: .5rem;
                padding-left: 1.5rem;
                @include media(set-min-w, $sm) {
                    padding-right: 1.5rem;
                }
                @include media(set-min-w, $md) {
                    padding-left: 3rem;
                    max-width: 36.5rem;
                }
            }

            .date {
                display: flex;
                align-items: center;
                margin-bottom: .7rem;
                color: #202020;
                font-size: 1.4rem;
                @include media(set-min-w, $sm) {
                    color: #3f3f3f;
                    font-size: 1.7rem;
                }

                img {
                    max-width: 30px;
                    margin-right: 1rem;
                    filter: invert(.2);
                    @include media(set-min-w, $sm) {
                        filter: invert(.3);
                    }
                }
            }

            .title {
                max-height: 4.4rem;
                line-height: normal;
                margin-bottom: .7rem;
                color: #3f3f3f;
                font-size: 1.6rem;
                font-weight: 700;
                overflow: hidden;
                @include media(set-min-w, $md) {
                    max-height: 6rem;
                    font-size: 2.2rem;
                    line-height: 2.8rem;
                }
            }

            .text {
                display: none;
                max-height: 5rem;
                line-height: 2.4rem;
                color: #7a7a7a;
                overflow: hidden;
                @include media(set-min-w, $sm) {
                    display: block;
                }
                @include media(set-min-w, $md) {
                    max-height: 7.5rem;
                }
            }
        }
    }
</style>